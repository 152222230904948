import React from "react"
import {
  withStyles,
  WithStyles,
  createStyles,
  Box,
  Theme,
} from "@material-ui/core"
import { PostForLanguage } from "../types/Post"
import PageContainer from "../components/PageContainer"
import marked from "marked"

const styles = ({ typography: { body1 }, breakpoints: { up } }: Theme) => {
  const xl = up("xl")
  return createStyles({
    root: {
      marginBottom: "4.625rem",
      [xl]: {
        marginBottom: "11.3125rem",
      },
    },
    box: {
      "& p, & li": {
        lineHeight: "1.975rem",
        ...body1,
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  body: PostForLanguage["body"]
}

const Content = ({ classes, body }: Props) => {
  const html: string = marked(body)
  return (
    <PageContainer className={classes.root}>
      <Box dangerouslySetInnerHTML={{ __html: html }} className={classes.box} />
    </PageContainer>
  )
}

export default withStyles(styles)(Content)
