import React from "react"
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Typography,
  Divider,
} from "@material-ui/core"

import { PostForLanguage } from "../../types/Post"
import HeroWithLogo from "../HeroWithLogo"

const styles = ({ breakpoints: { up } }: Theme) => {
  const lg = up("lg")
  const xl = up("xl")
  return createStyles({
    root: {},
    title: {
      marginBottom: "0.625rem",
    },
    divider: {
      marginBottom: "0.75rem",
      fontSize: "1.5rem",
      maxWidth: "6.5625rem",
      [lg]: {
        maxWidth: "18.75rem",
        marginBottom: "1rem",
      },
      [xl]: {
        maxWidth: "27.625rem",
        marginBottom: "1.625rem",
      },
    },
    description: {
      marginBottom: "5.125rem",
      [lg]: {
        marginBottom: "2.375rem",
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  title: PostForLanguage["title"]
  description: PostForLanguage["description"]
  excerpt: PostForLanguage["excerpt"]
  component?: React.ElementType<any>
}

const PostHero = ({
  classes,
  title,
  description,
  excerpt,
  component = "h1",
}: Props) => {
  return (
    <section className={classes.root}>
      <HeroWithLogo />
      <Typography className={classes.title} variant="h1" component={component}>
        {title}
      </Typography>
      <Divider className={classes.divider} />
      <Typography className={classes.description}>{description}</Typography>
      <Typography>{excerpt}</Typography>
    </section>
  )
}

export default withStyles(styles)(PostHero)
