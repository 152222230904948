import React from "react"

import Layout from "../components/Layout"
import Footer from "../components/Layout/Footer"
import DynamicCallToAction from "../components/Layout/Footer/DynamicCallToAction"
import NavWithLogo from "../components/Layout/NavWithLogo"
import SeoWithLanguage from "../components/SeoWithLanguage"
import Post, { PostPreview } from "../types/Post"
import Hero from "./Hero"
import RelatedPosts from "./RelatedPosts"
import { getPostLink } from "../utils/links"
import { QueryWithNodes } from "../types/queries"
import Content from "./Content"
import { LocalizedItemWithLocalizations } from "../types/Localization"
import { useRedirectToPageWithSelectedLanguage } from "../utils/redirect"

interface PostPreviewWithLocalization
  extends PostPreview,
    LocalizedItemWithLocalizations {}

interface Data {
  strapiWeblogs: Post
  allStrapiWeblogs: QueryWithNodes<PostPreviewWithLocalization>
}

interface Props {
  data: Data
}

const PostPage = ({
  data: {
    strapiWeblogs: post,
    allStrapiWeblogs: { nodes: posts },
  },
}: Props) => {
  useRedirectToPageWithSelectedLanguage<PostPreviewWithLocalization>(
    post,
    posts,
    getPostLink
  )

  const {
    title,
    description,
    excerpt,
    content,
    previewPicture,
    relatedPosts,
  } = post

  return (
    <Layout
      footer={<Footer callToAction={<DynamicCallToAction />} />}
      nav={<NavWithLogo />}
    >
      <SeoWithLanguage title={title} description={excerpt} />
      <Hero
        title={title}
        description={description}
        excerpt={excerpt}
        previewPicture={previewPicture}
      />
      <Content body={content} />
      <RelatedPosts posts={posts} relatedPostIds={relatedPosts} />
    </Layout>
  )
}

export default PostPage
