import React from "react"
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core"

import BaseHero from "../components/PostHero"
import Post from "../types/Post"
import PageContainer from "../components/PageContainer"
import Thumbnail from "../components/Thumbnail"

const styles = ({ breakpoints: { up } }: Theme) => {
  const xl = up("xl")
  return createStyles({
    root: {
      marginBottom: "4.0625rem",
      [xl]: {
        marginBottom: "5.6875rem",
      },
    },
    hero: {
      marginBottom: "2.8125rem",
      [xl]: {
        marginBottom: "4.375rem",
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  title: Post["title"]
  description: Post["description"]
  excerpt: Post["excerpt"]
  previewPicture: Post["previewPicture"]
}

const Hero = ({
  classes,
  title,
  description,
  excerpt,
  previewPicture,
}: Props) => {
  return (
    <section className={classes.root}>
      <PageContainer>
        <BaseHero
          title={title}
          description={description}
          excerpt={excerpt}
          classes={{ root: classes.hero }}
        />
        <Thumbnail imageData={previewPicture.localFile} alt={title} />
      </PageContainer>
    </section>
  )
}

export default withStyles(styles)(Hero)
