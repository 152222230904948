import React from "react"
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core"

import { PostPreview } from "../types/Post"
import PageContainer from "../components/PageContainer"
import PostSectionTitle from "../components/PostSectionTitle"
import Posts from "../components/Posts"
import { usePostLanguageText, usePostsForLanguage } from "../utils/language"
import { getNodeByIdFinder } from "../utils/find"
import { NodeWithId } from "../types/Nodes"

const styles = ({ breakpoints: { up } }: Theme) => {
  return createStyles({
    root: {
      marginBottom: "6.125rem",
      [up("xl")]: {
        marginBottom: "15.5625rem",
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  posts: PostPreview[]
  relatedPostIds: NodeWithId[]
}

const RelatedPosts = ({ classes, posts, relatedPostIds }: Props) => {
  const postsForLanguage: PostPreview[] = usePostsForLanguage(posts)

  const addRelatedPost = (
    relatedPosts: PostPreview[],
    { id }: NodeWithId
  ): PostPreview[] => {
    const relatedPost:
      | undefined
      | PostPreview = getNodeByIdFinder<PostPreview>()(id, postsForLanguage)
    if (relatedPost) {
      return [...relatedPosts, relatedPost]
    }
    return relatedPosts
  }

  const relatedPosts: PostPreview[] = relatedPostIds.reduce(addRelatedPost, [])

  if (!relatedPosts.length) return null

  const {
    relatedPosts: { title },
  } = usePostLanguageText()

  return (
    <section className={classes.root}>
      <PageContainer>
        <PostSectionTitle>{title}</PostSectionTitle>
        <Posts posts={relatedPosts} />
      </PageContainer>
    </section>
  )
}

export default withStyles(styles)(RelatedPosts)
